<script>
import api from '@/services/api';
import {
    BButton,
    BCard,
    BCardText,
    BCol,
    BFormGroup,
    BFormInput,
    BListGroupItem,
    BRow
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import draggable from 'vuedraggable';
import { regexTemplates } from '@/constants/regex.js';
import { sortItems, updateObjectToNew } from '@/util/helper';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';

export default {
    name: 'Socials',
    components: {
        ProgressLinear,
        PageTitle,
        TableActionBtns,
        BButton,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BCardText,
        BCard,
        BListGroupItem,
        vSelect,
        draggable
    },
    mixins: [CRUDPageMixin],
    data: () => ({
        socials: [],
        dataForModal: {
            title: '',
            type: '',
            url: '',
            position: ''
        },
        types: [
            {
                id: 1,
                name: 'facebook'
            },
            {
                id: 2,
                name: 'instagram'
            },
            {
                id: 3,
                name: 'tiktok'
            },
            {
                id: 4,
                name: 'telegram_channel'
            },
            {
                id: 5,
                name: 'telegram_bot'
            }
        ],
        urlRegex: regexTemplates.url,
        requestPending: true
    }),
    computed: {
        computedModalTitle() {
            return this.updatingItem ? this.$t('edit.social') : this.$t('add.social');
        }
    },
    methods: {
        async getSocials() {
            this.requestPending = true;
            const { data } = await api.socials.get();
            this.socials = data;
            this.sortSocialsByPosition();
            this.requestPending = false;
        },

        async create() {
            const { data } = await api.socials.post(this.dataForModal);
            this.socials.unshift(data);
            this.sortSocialsByPosition();
            this.$toast.success(this.$t('success.added'));
        },

        async update() {
            const { data } = await api.socials.put(this.updatingItem.id, this.dataForModal);
            this.socials.forEach((social) => {
                if (social.id === this.updatingItem.id) {
                    updateObjectToNew(social, data);
                }
            });
            this.sortSocialsByPosition();
            this.$toast.success(this.$t('success.updated.title'));
        },

        async updatePosition({ moved }) {
            const id = moved.element.id;
            const position = ++moved.newIndex;

            await api.socials
                .put(id, { position })
                .then(() => {
                    this.$toast.success(this.$t('success.updated.position'));
                })
                .catch(() => {
                    this.$toast.error(this.$t('error.smth'));
                });
        },

        async deleteSocial(id, index) {
            await api.socials.delete(id);
            this.socials.splice(index, 1);
        },

        sortSocialsByPosition() {
            this.socials = sortItems(this.socials, 'position');
        }
    },
    mounted() {
        this.getSocials();
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title>
                {{ $t('navigation.socials') }}
            </template>
            <template #button v-if="hasAccess('socials', 'create')">
                <b-button v-b-modal.createUpdateModal variant="success" class="ml-auto">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <div class="card">
            <progress-linear v-if="requestPending" />
            <table v-else class="informations-draggable-table">
                <thead>
                    <tr>
                        <th></th>
                        <th>
                            <div class="d-flex justify-content-center">#</div>
                        </th>
                        <th>
                            {{ $t('titles.title') }}
                        </th>
                        <th>
                            {{ $t('titles.type') }}
                        </th>
                        <th>
                            {{ $t('titles.url') }}
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <draggable v-model="socials" tag="tbody" handle=".handle" @change="updatePosition">
                    <tr v-for="(social, index) in socials" :key="social.index">
                        <td>
                            <div class="handle cursor-move">
                                <feather-icon icon="AlignJustifyIcon" />
                            </div>
                        </td>
                        <td>
                            <div class="d-flex justify-content-center">
                                {{ index + 1 }}
                            </div>
                        </td>
                        <td>
                            {{ social.title }}
                        </td>
                        <td>
                            {{ social.type }}
                        </td>
                        <td>
                            {{ social.url }}
                        </td>
                        <td>
                            <table-action-btns
                                :item="social"
                                :index="index"
                                :delete-handler="deleteSocial"
                                :delete-access="{ section: 'socials', permission: 'delete' }"
                                :update-access="{ section: 'socials', permission: 'update' }"
                                @openModal="openModalToUpdate"
                            />
                        </td>
                    </tr>
                </draggable>
            </table>
        </div>

        <b-modal
            v-model="isOpenModal"
            id="createUpdateModal"
            cancel-variant="danger"
            @hidden="onCloseModal"
            centered
            :hide-footer="false"
            :no-close-on-backdrop="false"
            size="sm"
            :title="computedModalTitle"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    TITLE    -->
                    <b-col cols="12">
                        <label>
                            {{ $t('titles.title') }}
                        </label>
                        <ValidationProvider
                            :name="$t('titles.title')"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.title"
                                    size="lg"
                                    :placeholder="$t('enter.title')"
                                    :state="errors.length > 0 ? false : null"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    TYPE    -->
                    <b-col cols="12">
                        <label>
                            {{ $t('titles.type') }}
                        </label>
                        <ValidationProvider
                            :name="$t('titles.type')"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <v-select
                                    v-model="dataForModal.type"
                                    label="name"
                                    :options="types"
                                    :reduce="(type) => type.name"
                                    :searchable="false"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    URL    -->
                    <b-col cols="12">
                        <label>
                            {{ $t('titles.url') }}
                        </label>
                        <ValidationProvider
                            :name="$t('titles.url')"
                            :rules="{
                                required: true,
                                regex: urlRegex
                            }"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.url"
                                    size="lg"
                                    :placeholder="$t('enter.url')"
                                    :state="errors.length > 0 ? false : null"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    Position    -->
                    <b-col cols="12">
                        <label>
                            {{ $t('titles.position') }}
                        </label>
                        <ValidationProvider
                            :name="$t('titles.position')"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.position"
                                    size="lg"
                                    :placeholder="$t('enter.position')"
                                    :state="errors.length > 0 ? false : null"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </ValidationObserver>
            <template #modal-footer>
                <b-button @click="modalSubmit" variant="success">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
